import { useNavigate } from 'react-router-dom';
import './styles/Lesson.scss'

export default function Lesson({number, title, motto, locked = false, completed = false, icons = null} : any) {
    const navigate = useNavigate()

    return (
        <>
            <div onClick={() => {if (!locked){sessionStorage.setItem('scrollPosition', window.scrollY.toString()); navigate(number)}}} className={"lesson " + (locked ? "locked" : "")} style={{zIndex: 1000 - number}}>
                <div>
                    <h1>
                        {number}.<span />{title}
                    </h1>
                    <h2>
                        "{motto}"
                    </h2>
                    <img className="symbols" src="/images/components/symbols.png" alt="" />
                    {completed && <img className="completed" src="/images/components/completed.png" alt="" />}
                </div>
            </div>
        </>
    );
}
