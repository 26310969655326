import { Link, useNavigate } from 'react-router-dom';
import './styles/Home.scss'
import ReactGA from "react-ga4";

export default function Home() {
    const navigate = useNavigate();

    ReactGA.initialize("G-CQ1EYNNMH2");
    ReactGA.send({ hitType: "pageview homepage", page: "/", title: "Home Page" });

    return (
        <div className="home">
            <div className="headBlock">
                <div className="text">
                    <h1>
                        Přípravné kurzy <br/>
                        k&nbsp;přijímačkám <br/>
                        na medicínu <br/>
                    </h1>
                    <div className="block" >
                        <h2>
                            V rámci našich kurzů nabízíme komplexní přípravu k přijímacím zkouškám na lékařské fakulty po celé ČR
                        </h2>
                        <div className="button" onClick={() => navigate("/registrace")}>
                            <p>
                                CHCI KURZ
                            </p>
                        </div>
                    </div>
                </div>
                <div className="image">
                    <img src="images/pages/main.png" alt="" />
                </div>
            </div>
            
            <div className="textBlock">
                <h1 className="header">
                    Co s naším kurzem získáš?
                </h1>
                <div className="row" >
                    <div className="image" >
                        <img src="/images/pages/main1.JPG" alt="" />
                        <img className="cat" src="/images/pages/cat.png" alt="" />
                    </div>
                    <div className="text" >
                        <h2>
                            Studijní texty
                        </h2>
                        <p>
                            U nás najdeš komplexní studijní materiály vytvořené pro <b>efektivní studium</b>. Studijní texty jsou psané 
                            formou  <b>Cornellovy metody</b>, díky čemuž uvidíš na co se v kapitole zaměřit a po samotném obsahu kapitoly 
                            tě čeká <b>opakování a souhrn</b> podstatného.
                        </p> 

                        <h2>
                            Videa
                        </h2>
                        <p>
                            Nevíš si rady s nějakým příkladem? Máme pro tebe připravená videa s vysvětlením, kde si ukážeme <b>řešení krok za krokem</b>.
                        </p> 

                        <h2>
                            Procvičování a testy
                        </h2>
                        <p>
                            Protože <b>opakování je klíčem k úspěchu</b>, za každou kapitolou na tebe čeká procvičování v podobě 
                            otázek typově podobných modelovým otázkám. Zároveň je v rámci každé kapitoly k dispozici test, který ti dá zpětnou vazbu.
                        </p> 
                    </div>
                </div>

                <div className="row" >
                    <div className="image" >
                        <img src="/images/pages/main2.JPG" alt="" />
                    </div>
                    <div className="text" >
                        <h2>
                            Bonusové materiály
                        </h2>
                        <p>
                            Kromě zpracovaných studijních textů pro tebe máme připravené také <b>bonusové materiály</b>, 
                            jako jsou tipy a triky k přijímačkám a pohovorům, připravené studijní plány, anebo přehledové tabulky.
                        </p>  

                        <h2>
                            Od mediků
                        </h2>
                        <p>
                            <b>Sami jsme si prošli přes přijímačky</b> a víme na co se <b>zaměřit</b> a co naopak není až tak podstatné, podle
                            toho jsou kurzy také vytvořeny. Ve studijních materiálech najdeš <b>poznámky</b>, 
                            které tě na takové věci upozorní.
                        </p> 

                        <h2>
                            Maturita
                        </h2>
                        <p>
                            Naše materiály můžeš využít i k <b>přípravě na maturitu</b>.
                        </p> 
                    </div>
                </div>
            </div>

            <div className="linesHolder">
                <div className="lines">
                    <img src="images/pages/mainLines/line1.png" alt="" />
                    <img src="images/pages/mainLines/line2.png" alt="" />
                    <img src="images/pages/mainLines/line3.png" alt="" />
                </div>
            </div>
            <div className="social">
                <h1 className="header">
                    Sleduj nás na instagramu
                </h1>

                <div className="postLine">
                    <a href={"https://www.instagram.com/p/DCZrHFqRQdv/?igsh=NWNjeTducDdiZ2Rx"} className="post">
                        <img src="/images/pages/igPosts/1.png" alt="" />
                    </a>
                    <a href={"https://www.instagram.com/p/DCZr_jnsS3E/?igsh=a2tubWExMmU1cGZm"} className="post">
                        <img src="/images/pages/igPosts/2.png" alt="" />
                    </a>
                    <a href={"https://www.instagram.com/p/DCZslSUsDsG/?igsh=YzYzNWp1MmdzbnVl"} className="post">
                        <img src="/images/pages/igPosts/3.png" alt="" />
                    </a>
                    <a href={"https://www.instagram.com/p/DCb6RWFMSKG/?igsh=azFzeHNkajEzMGRo"} className="post">
                        <img src="/images/pages/igPosts/4.png" alt="" />
                    </a>

                    <img className="penguin" src="/images/pages/penguin.png" alt="" />
                </div>
            </div>
        </div>
    );
}
